import React, { Fragment } from 'react';
import Header from "./elements/Header";
import LegacyFooter from './legacy-footer';
import LegacyFooterHome from './legacy-footer-home';
import CookieConsent from 'react-cookie-consent';

export default function LegacyContainer(props) {
  return (
    <Fragment>
      <Header />
      {props.children}
      {props.homePage ? <LegacyFooterHome /> : <LegacyFooter />}
      <CookieConsent>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </Fragment>
  );
}