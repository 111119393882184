import React from 'react';
import { StaticQuery, graphql } from "gatsby"
import facebook from '../assets/facebook.png';
import twitter from '../assets/twitter.png';
import instagram from '../assets/instagram.png';
import soundcloud from '../assets/soundcloud.png'
import spotify from '../assets/spotify.png'

function LegacyFooter({ data }) {
  const links = data.allMarkdownRemark.edges[0].node.frontmatter;
  return (
    <div className="footer-container no-select">
      <div className="footer">
        <div className="social-links">
          <a href={links.facebook} target="_new"><img src={facebook} alt="" /></a><a href={links.twitter} target="_new"><img src={twitter} alt="" /></a><a href={links.instagram} target="_new"><img src={instagram} alt="" /></a><a href={links.soundcloud} target="_new"><img src={soundcloud} alt="" /></a><a href={links.spotify} target="_new"><img src={spotify} alt="" /></a>
        </div>
      </div>
    </div>
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "meta-social-links" } }}
          limit: 1
        ) {
          edges {
            node {
              frontmatter {
                facebook
                twitter
                instagram
                soundcloud
                spotify
              }
            }
          }
        }
      }
    `}
    render={data => <LegacyFooter data={data} {...props} />}
  />
)