import React, { useState } from 'react';
import { graphql, Link, useStaticQuery } from "gatsby"

import logo from '../../assets/logo.png';
import invLogo from '../../assets/logo_inv.png';
import facebookLogo from '../../assets/facebook_inv.png';
import twitterLogo from '../../assets/twitter_inv.png';
import instagramLogo from '../../assets/instagram_inv.png';
import soundcloudLogo from '../../assets/soundcloud_inv.png'
import spotifyLogo from '../../assets/spotify_inv.png'

const Header = () => {

  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const toggleMenu = () => setShowMobileMenu(!showMobileMenu)

  const data = useStaticQuery(graphql`
  {
    clientMenuData: allFile(sort: {fields: childMarkdownRemark___frontmatter___menuOrder}, filter: {sourceInstanceName: {eq: "clients"}}) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            heading
            pageSlug
          }
        }
      }
    }
    servicesMenuData: allFile(sort: {fields: childMarkdownRemark___frontmatter___menuOrder}, filter: {sourceInstanceName: {eq: "services"}}) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            heading
            pageSlug
          }
        }
      }
    }
    musicMenuData: allFile(sort: {fields: childMarkdownRemark___frontmatter___menuOrder}, filter: {sourceInstanceName: {eq: "music"}}) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            heading
            pageSlug
          }
        }
      }
    }
    djMenuData: allFile(sort: {fields: childMarkdownRemark___frontmatter___menuOrder}, filter: {sourceInstanceName: {eq: "dj"}}) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            heading
            pageSlug
          }
        }
      }
    }
    socialsData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "meta-social-links" } }}
      limit: 1
    ) {
      edges {
        node {
          frontmatter {
            facebook
            twitter
            instagram
            soundcloud
            spotify
          }
        }
      }
    }
  }
  `)

  const socialLinks = data.socialsData.edges[0].node.frontmatter
  const clientsMenuData = mapSubMenuItems(data.clientMenuData)
  const servicesMenuData = mapSubMenuItems(data.servicesMenuData)
  const musicMenuData = mapSubMenuItems(data.musicMenuData)
  const djMenuData = mapSubMenuItems(data.djMenuData)

  return (
    <div className="rsm-header">
      <div className="rsm-header-logo-container">
        <Link to="/" style={{ border: 'none' }}>
          <img src={logo} className="rsm-header-logo" alt="" />
        </Link>
      </div>
      <ul className="rsm-desktop-menu-container">
        <li className="rsm-desktop-menu-item">
          <Link to="/dj" activeClassName="active-menu">DJ</Link>
          {djMenuData && djMenuData.length > 0 ? (<ul className="rsm-desktop-sub-menu-list">
            {djMenuData.map((data, index) => <li key={index} className="rsm-desktop-sub-menu-list-item"><Link to={`/dj/${data.url}`} activeClassName="active-menu" >{data.text}</Link></li>)}
          </ul>) : null}
        </li>
        <li className="rsm-desktop-menu-item">
          <Link to="/live" activeClassName="active-menu">Live</Link>
        </li>
        <li className="rsm-desktop-menu-item">
          <Link to="/services" activeClassName="active-menu" partiallyActive>Services</Link>
          {servicesMenuData && servicesMenuData.length > 0 ? (<ul className="rsm-desktop-sub-menu-list">
            {servicesMenuData.map((data, index) => <li key={index} className="rsm-desktop-sub-menu-list-item"><Link to={`/services/${data.url}`} activeClassName="active-menu" >{data.text}</Link></li>)}
          </ul>) : null}
        </li>

        <li className="rsm-desktop-menu-item">
          <Link to="/music" activeClassName="active-menu">Music</Link>
          {musicMenuData && musicMenuData.length > 0 ? (<ul className="rsm-desktop-sub-menu-list">
            {musicMenuData.map((data, index) => <li key={index} className="rsm-desktop-sub-menu-list-item"><Link to={`/music/${data.url}`} activeClassName="active-menu" >{data.text}</Link></li>)}
          </ul>) : null}
        </li>
        <li className="rsm-desktop-menu-item">
          <Link to="/clients" activeClassName="active-menu" partiallyActive>Clients</Link>
          {clientsMenuData && clientsMenuData.length > 0 ? (<ul className="rsm-desktop-sub-menu-list">
            {clientsMenuData.map((data, index) => <li key={index} className="rsm-desktop-sub-menu-list-item"><Link to={`/clients/${data.url}`} activeClassName="active-menu" >{data.text}</Link></li>)}
          </ul>) : null}
        </li>
        <li className="rsm-desktop-menu-item">
          <Link to="/testimonials" activeClassName="active-menu">Testimonials</Link>
        </li>
        <li className="rsm-desktop-menu-item">
          <Link to="/bookings" activeClassName="active-menu">Bookings</Link>
        </li>
      </ul>

      <div>
        <div className="mobile-menu-toggle" onClick={toggleMenu}>
          <button className={showMobileMenu ? 'hamburger hamburger--spin is-active' : 'hamburger hamburger--spin'} type="button">
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
        {showMobileMenu ? <div className="rsm-mobile-menu-container mobile-popover-open">
          <div className="rsm-mobile-menu-logo">
            <Link to="/" style={{ border: 'none' }}>
              <img src={invLogo} className="rsm-header-logo" alt="" />
            </Link>
          </div>
          <ul className="rsm-mobile-menu-ul">
            <li className="rsm-mobile-menu-item">
              <Link to="/dj/">DJ</Link>
            </li>
            <li className="rsm-mobile-menu-item">
              <Link to="/live/">Live</Link>
            </li>
            <li className="rsm-mobile-menu-item">
              <Link to="/services/">Services</Link>
            </li>
            <li className="rsm-mobile-menu-item">
              <Link to="/music/">Music</Link>
            </li>
            <li className="rsm-mobile-menu-item">
              <Link to="/clients/">Clients</Link>
            </li>
            <li className="rsm-mobile-menu-item">
              <Link to="/testimonials/">Testimonials</Link>
            </li>
            <li className="rsm-mobile-menu-item">
              <Link to="/bookings/">Bookings</Link>
            </li>
          </ul>
          <div className="rsm-mobile-menu-socials"><a href={socialLinks.facebook} target="_new"><img src={facebookLogo} alt="" /></a><a href={socialLinks.twitter} target="_new"><img src={twitterLogo} alt="" /></a><a href={socialLinks.instagram} target="_new"><img src={instagramLogo} alt="" /></a><a href={socialLinks.soundcloud} target="_new"><img src={soundcloudLogo} alt="" /></a><a href={socialLinks.spotify} target="_new"><img src={spotifyLogo} alt="" /></a>
          </div>
        </div> : null
        }
      </div>
    </div>
  );
}

export default Header

const mapSubMenuItems = (data) => {
  return data.nodes && data.nodes.length > 0 ? data.nodes.map(mapMenuData) : []
}

const mapMenuData = menuItem => {
  return {
    text: menuItem.childMarkdownRemark.frontmatter.heading,
    url: menuItem.childMarkdownRemark.frontmatter.pageSlug
  }
}