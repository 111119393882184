import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const SEO = (props) => {
  const metaData = props.data.allMarkdownRemark.edges[0].node.frontmatter;
  const title = props.pageTitle ? `${props.pageTitle} - ${metaData.title}` : metaData.title;
  const schemaMarkup = {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "Dom Booth DJ",
    "url": "https://www.dboothdj.com/",
    "sameAs": [
      "https://www.facebook.com/dboothdj",
      "https://twitter.com/dboothdj",
      "https://www.instagram.com/dboothdj/",
      "https://soundcloud.com/dboothdj"
    ]
  };

  return (
    <Helmet
      title={title}>
      <meta property="og:title" content={metaData.title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={metaData.description} />
      <meta name="description" content={metaData.description} />
      <meta property="og:url" content="http://www.dboothdj.com/" />
      <meta property="og:image" content={`http://www.dboothdj.com${metaData.ogImage}`} />
      <script type="application/ld+json">
        {JSON.stringify(schemaMarkup)}
      </script>
    </Helmet>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query  {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "meta-seo" } }}
          limit: 1
        ) {
          edges {
            node {
              frontmatter {
                title
                description
                ogImage
              }
            }
          }
        }
      }
    `}
    render={data => <SEO data={data} {...props} />}
  />
)